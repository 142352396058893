import { Button, Container, Heading, HStack, useBreakpointValue } from '@chakra-ui/react'
import NavDropDown from '@v1_molecules/NavDropDown/NavDropDown.component'
import NavMenuOptions from '@v1_molecules/NavDropDown/NavMenuOptions'

function NavHeading({
    headingText,
    menuColour,
}: {
    headingText: string
    menuColour: 'grey' | 'green'
}) {
    const isMobile = useBreakpointValue({ base: true, lg: false })
    const textColor = 'plejGreen.500' // Always use green for better contrast on white background

    return (
        <Container maxW="1200px">
            <HStack justifyContent="space-between" w="full" py={4} align="center">
                <Heading
                    as="h1"
                    textTransform="capitalize"
                    fontWeight="medium"
                    fontSize="3xl"
                    textColor={textColor}
                >
                    <a href="/">{headingText}</a>
                </Heading>

                {/* Desktop Navigation */}
                {!isMobile && (
                    <HStack spacing={4}>
                        {NavMenuOptions.map((name) => (
                            <Button
                                key={name}
                                variant="ghost"
                                color={textColor}
                                textTransform="capitalize"
                                fontSize="md"
                                fontWeight="medium"
                                _hover={{
                                    textDecoration: 'none',
                                    bg: 'blackAlpha.50',
                                }}
                                as="a"
                                href={`/${name}`}
                            >
                                {name}
                            </Button>
                        ))}
                        <Button
                            bg={textColor}
                            color="white"
                            size="md"
                            fontWeight="medium"
                            _hover={{
                                bg: 'plejGreen.600',
                            }}
                            as="a"
                            href="/login"
                        >
                            Login
                        </Button>
                    </HStack>
                )}

                {/* Mobile Navigation */}
                {isMobile && <NavDropDown menuColour={menuColour} />}
            </HStack>
        </Container>
    )
}

export default NavHeading
