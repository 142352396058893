import { Box, Container, Heading, Link, Text, VStack, useBreakpointValue } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'

function ContactPage() {
    const contactUsWords = `If you want to make an event happen, have feedback, or just want to say hi, speak with us. We're listening! :)`

    // Responsive width settings to match other pages
    const maxContentWidth = useBreakpointValue({ base: '100%', md: '1200px' })

    return (
        <Box w="100%" minH="100vh" bg="white">
            <NavHeading menuColour="grey" headingText="Turbafi" />

            <Container maxW={maxContentWidth} py={{ base: 10, md: 20 }}>
                <VStack spacing={8} align="center">
                    <VStack spacing={4} textAlign="center" maxW="800px">
                        <Heading
                            as="h2"
                            fontSize={{ base: '3xl', md: '4xl' }}
                            color="plejGreen.500"
                            fontWeight="bold"
                        >
                            Contact us
                        </Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" px={4}>
                            {contactUsWords}
                        </Text>
                    </VStack>

                    <Link
                        href="mailto:sean@commonwealthventures.co.uk?Subject=Working with Turbafi"
                        color="plejGreen.500"
                        fontSize={{ base: 'md', md: 'lg' }}
                        fontWeight="medium"
                        _hover={{
                            textDecoration: 'none',
                            color: 'plejGreen.600',
                        }}
                    >
                        Email Sean at sean@commonwealthventures.co.uk
                    </Link>

                    <VStack color="gray.600" fontSize={{ base: 'sm', md: 'md' }} spacing={1} pt={8}>
                        <Text fontWeight="medium" color="plejGreen.500">
                            Turbafi
                        </Text>
                        <Text>12 Jordan Street</Text>
                        <Text>Liverpool</Text>
                        <Text>L1 0BP</Text>
                    </VStack>
                </VStack>
            </Container>
        </Box>
    )
}

export default ContactPage
