import { ChakraProvider, Container } from '@chakra-ui/react'
import { StytchProvider } from '@stytch/react'
import { StytchHeadlessClient } from '@stytch/vanilla-js/headless'
import AccountDetails from '@v1_pages/AccountDetails/AccountDetails.component'
import AddEventImages from '@v1_pages/AddEventImages/AddEventImages.component'
import Authenticate from '@v1_pages/Authenticate/Authenticate.component'
import ChallengeMfa from '@v1_pages/ChallengeMfa/ChallengeMfa.component'
import ChallengeSms from '@v1_pages/ChallengeSms/ChallengeSms.component'
import ConfigureMfa from '@v1_pages/ConfigureMfa/ConfigureMfa.component'
import ConfigureSms from '@v1_pages/ConfigureSms/ConfigureSms.component'
import ConfirmAndPay from '@v1_pages/ConfirmAndPay/ConfirmAndPay.component'
import ContactPage from '@v1_pages/Contact/ContactPage.component'
import CreateEventPageBody from '@v1_pages/CreateEvent/CreateEvent.component'
import DraftEvents from '@v1_pages/DraftEvents/DraftEvents.component'
import V1EventPage from '@v1_pages/Event/EventPage.component'
import Events from '@v1_pages/Events/Events.component'
import HomePage from '@v1_pages/Home/HomePage.component'
import InvestorsPage from '@v1_pages/Investors/Investors.component'
import LoginOrSignUp from '@v1_pages/LoginOrSignUp/LoginOrSignUp.component'
import NotFound from '@v1_pages/NotFound/NotFound.component'
import RecoveryCodes from '@v1_pages/RecoveryCodes/RecoveryCodes.component'
import ResetMfa from '@v1_pages/ResetMfa/ResetMfa.component'
import SuccessPage from '@v1_pages/Success/SuccessPage.component'
import TeamPage from '@v1_pages/Team/Team.component'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import posthog from 'posthog-js'
import theme from './theme'

posthog.init(process.env.REACT_APP_POSTHOG_PROJECT_API_KEY!, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always', // identified_only or 'always' to create profiles for anonymous users as well
})

const queryClient = new QueryClient()
const stytch = new StytchHeadlessClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN!)

function App() {
    return (
        <ChakraProvider theme={theme}>
            <StytchProvider stytch={stytch}>
                <Container
                    boxSizing="border-box"
                    w="100vw"
                    maxW="1920px"
                    m="0"
                    p="0"
                    alignSelf="start"
                >
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/about" element={<HomePage />} />
                                <Route path="/team" element={<TeamPage />} />
                                <Route path="/contact" element={<ContactPage />} />
                                <Route path="/invest" element={<InvestorsPage />} />
                                <Route
                                    path="/organiser/create-event"
                                    element={<CreateEventPageBody />}
                                />
                                <Route
                                    path="/organiser/create-event/:id/images"
                                    element={<AddEventImages />}
                                />
                                <Route path="/events" element={<Events />} />
                                <Route path="/events/:id" element={<V1EventPage />} />
                                <Route path="/events/:id/confirm" element={<ConfirmAndPay />} />
                                <Route path="/events/:id/success" element={<SuccessPage />} />
                                <Route path="/organiser/drafts" element={<DraftEvents />} />
                                <Route path="/login" element={<LoginOrSignUp />} />
                                <Route path="/authenticate" element={<Authenticate />} />
                                <Route path="/account/configure-mfa" element={<ConfigureMfa />} />
                                <Route path="/account/challenge-mfa" element={<ChallengeMfa />} />
                                <Route path="/account/configure-sms" element={<ConfigureSms />} />
                                <Route path="/account/challenge-sms" element={<ChallengeSms />} />
                                <Route path="/account/reset-mfa" element={<ResetMfa />} />
                                <Route path="/account/details" element={<AccountDetails />} />
                                <Route path="/account/recovery-codes" element={<RecoveryCodes />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </BrowserRouter>
                    </QueryClientProvider>
                </Container>
            </StytchProvider>
        </ChakraProvider>
    )
}

export default App
