import { Grid, GridItem, Spinner, Text, VStack } from '@chakra-ui/react'
import { EventV1 } from '@commonwealthventures/poc-readstore-v1-models'
import EventResultItem from '@v1_organisms/EventResultItem/EventResultItem.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useStytch, useStytchUser } from '@stytch/react'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import axios from 'axios'
import { useQuery } from 'react-query'

function DraftEventsPage() {
    const stytch = useStytch()
    const { user } = useStytchUser()

    const { data, error, isLoading } = useQuery<EventV1[]>({
        queryKey: ['DRAFT_EVENTS_V1'],
        queryFn: async () => {
            if (!user) {
                return []
            }

            let tokens
            try {
                tokens = stytch.session.getTokens()
            } catch (err) {
                throw new Error('Failed to get authentication tokens')
            }

            if (!tokens) {
                throw new Error('No authentication tokens available')
            }

            const { organiserDetails } = user!.trusted_metadata! as {
                organiserDetails: {
                    id: string
                }
            }

            const result = await axios.get(
                `${process.env.REACT_APP_ADMIN_API_URL}/fund/organiser/${organiserDetails.id}/drafts`,
                {
                    headers: {
                        'x-API-Key': process.env.REACT_APP_ADMIN_API_KEY!,
                        Authorization: tokens.session_jwt,
                    },
                },
            )

            return result.data
        },
        retry: 0,
        enabled: !!user,
    })

    if (error) {
        return (
            <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="white">
                <NavHeading menuColour="grey" headingText="Turbafi" />
                <VStack justifyContent="center" flex={1}>
                    <Text>Sorry. An error occurred loading your draft events.</Text>
                    <Text color="gray.600" fontSize="sm">
                        {error instanceof Error ? error.message : 'Unknown error'}
                    </Text>
                </VStack>
            </VStack>
        )
    }

    if (isLoading) {
        return (
            <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="white">
                <NavHeading menuColour="grey" headingText="Turbafi" />
                <VStack justifyContent="center" flex={1}>
                    <Spinner size="xl" thickness="4px" speed="0.75s" color="plejGreen.500" />
                    <Text fontSize="xl" color="plejGreen.500">
                        Loading your drafts...
                    </Text>
                </VStack>
            </VStack>
        )
    }

    if (!data || data.length === 0) {
        return (
            <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="white">
                <NavHeading menuColour="grey" headingText="Turbafi" />
                <VStack justifyContent="center" flex={1}>
                    <Text fontSize="xl">You don&apos;t have any draft events yet.</Text>
                    <Text color="gray.600">
                        Start creating an event and save it as a draft to see it here.
                    </Text>
                </VStack>
            </VStack>
        )
    }

    return (
        <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="white">
            <NavHeading menuColour="grey" headingText="Turbafi" />
            <VStack w="full" maxW="1200px" px={4} spacing={8}>
                <Text fontSize="2xl" fontWeight="bold" alignSelf="flex-start">
                    Your Draft Events
                </Text>
                <Grid
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(4, 1fr)',
                    }}
                    gap="6"
                    w="full"
                >
                    {data.map((event) => (
                        <GridItem key={event.id}>
                            <EventResultItem event={event} />
                        </GridItem>
                    ))}
                </Grid>
            </VStack>
        </VStack>
    )
}

export default function DraftEvents() {
    return (
        <ProtectedPage>
            <DraftEventsPage />
        </ProtectedPage>
    )
}
