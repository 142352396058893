import {
    Box,
    Button,
    Container,
    Grid,
    HStack,
    Image,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import MockEvent from '../../../mocks/Event'

function HomePage() {
    // Modern, punchy copy inspired by Craft's style
    const heroTitle = 'Make anything happen.'
    const heroSubtitle = 'The intelligent way to create, manage and optimize your events.'
    const featureTitle = 'Transform your event planning'
    const featureDescription =
        'Turn audience interest into committed attendance with our intelligent event platform.'

    // Responsive width settings
    const maxContentWidth = useBreakpointValue({ base: '100%', md: '1200px' })

    return (
        <Box w="100%" minH="100vh" bg="white">
            {/* Navigation */}
            <NavHeading menuColour="grey" headingText="Turbafi" />

            {/* Hero Section */}
            <Container maxW={maxContentWidth} py={{ base: 10, md: 20 }}>
                <Grid
                    templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                    gap={{ base: 8, lg: 16 }}
                    alignItems="center"
                >
                    <VStack align="flex-start" spacing={6}>
                        <Text
                            fontSize={{ base: '4xl', md: '6xl' }}
                            fontWeight="bold"
                            lineHeight="1.2"
                            color="plejGreen.500"
                        >
                            {heroTitle}
                        </Text>
                        <Text fontSize={{ base: 'xl', md: '2xl' }} color="gray.600" maxW="600px">
                            {heroSubtitle}
                        </Text>
                        <HStack spacing={4} pt={4}>
                            <Button
                                size="lg"
                                bg="plejGreen.500"
                                color="white"
                                px={8}
                                _hover={{ bg: 'plejGreen.600' }}
                                as="a"
                                href="/login"
                            >
                                Get Started
                            </Button>
                            <Button
                                size="lg"
                                variant="outline"
                                colorScheme="green"
                                px={8}
                                as="a"
                                href="/about"
                            >
                                Learn More
                            </Button>
                        </HStack>
                    </VStack>

                    {/* Hero Image/Illustration */}
                    <Box
                        position="relative"
                        height={{ base: '300px', md: '400px' }}
                        bg="gray.100"
                        borderRadius="xl"
                        overflow="hidden"
                    >
                        <Image
                            src={MockEvent.images.primary.path}
                            alt="Event Planning Platform"
                            objectFit="cover"
                            w="100%"
                            h="100%"
                        />
                    </Box>
                </Grid>
            </Container>

            {/* Features Section */}
            <Box bg="gray.50" py={{ base: 16, md: 24 }}>
                <Container maxW={maxContentWidth}>
                    <VStack spacing={16}>
                        <VStack spacing={4} textAlign="center" maxW="800px">
                            <Text
                                fontSize={{ base: '3xl', md: '4xl' }}
                                fontWeight="bold"
                                color="plejGreen.500"
                            >
                                {featureTitle}
                            </Text>
                            <Text fontSize={{ base: 'lg', md: 'xl' }} color="gray.600">
                                {featureDescription}
                            </Text>
                        </VStack>

                        {/* Feature Grid */}
                        <Grid
                            templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                            gap={8}
                            w="100%"
                        >
                            {/* Feature 1 */}
                            <VStack
                                bg="white"
                                p={8}
                                borderRadius="xl"
                                boxShadow="md"
                                spacing={4}
                                align="flex-start"
                            >
                                <Box bg="plejGreen.100" p={3} borderRadius="md">
                                    {/* Icon placeholder */}
                                </Box>
                                <Text fontSize="xl" fontWeight="bold" color="plejGreen.500">
                                    Discover
                                </Text>
                                <Text color="gray.600">
                                    Find the perfect event format for your audience
                                </Text>
                            </VStack>

                            {/* Feature 2 */}
                            <VStack
                                bg="white"
                                p={8}
                                borderRadius="xl"
                                boxShadow="md"
                                spacing={4}
                                align="flex-start"
                            >
                                <Box bg="plejGreen.100" p={3} borderRadius="md">
                                    {/* Icon placeholder */}
                                </Box>
                                <Text fontSize="xl" fontWeight="bold" color="plejGreen.500">
                                    Optimize
                                </Text>
                                <Text color="gray.600">
                                    Price and de-risk your events intelligently
                                </Text>
                            </VStack>

                            {/* Feature 3 */}
                            <VStack
                                bg="white"
                                p={8}
                                borderRadius="xl"
                                boxShadow="md"
                                spacing={4}
                                align="flex-start"
                            >
                                <Box bg="plejGreen.100" p={3} borderRadius="md">
                                    {/* Icon placeholder */}
                                </Box>
                                <Text fontSize="xl" fontWeight="bold" color="plejGreen.500">
                                    Engage
                                </Text>
                                <Text color="gray.600">
                                    Turn interest into committed attendance
                                </Text>
                            </VStack>
                        </Grid>
                    </VStack>
                </Container>
            </Box>

            {/* Social Proof Section */}
            <Container maxW={maxContentWidth} py={{ base: 16, md: 24 }}>
                <VStack spacing={8} textAlign="center">
                    <Text
                        fontSize={{ base: '2xl', md: '3xl' }}
                        fontWeight="bold"
                        color="plejGreen.500"
                    >
                        Trusted by Event Creators Worldwide
                    </Text>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={8} w="100%">
                        {/* Add partner/client logos here */}
                    </Grid>
                </VStack>
            </Container>
        </Box>
    )
}

export default HomePage
