import { Avatar, Heading, Text, VStack } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import Andy from './Andy.jpeg'
import George from './George.jpeg'
import Sean from './Sean.jpeg'

const teamDetails = [
    {
        name: 'Sean Harris',
        image: Sean,
        bio: 'Sean is a software engineer with a passion for creating innovative solutions.',
    },
    {
        name: 'Andy Hurt',
        image: Andy,
        bio: `Andy spent 4 years at IBM making sick blockchain stuff & opensourcing on Linux foundation projects. For his sins, he's a Derby Country devotee and enjoys walking in the greatest country (Derbyshire incase anyone from Yorkshire gets confused).`,
    },
    {
        name: 'George Jones',
        image: George,
        bio: `George grabbed a Masters in Mechanical Engineering from Cardiff and cut his teeth as a platform & devops engineer before switching k8s for the world of serverless. Likes snowboarding, travelling, watching sports - and playing them when he can. Probably has the best lid on the team much to our chagrin.`,
    },
]

function TeamPage() {
    return (
        <VStack minH="100vh" spacing={8} pb="4" color="plejGreen.500" bg="white">
            <NavHeading menuColour="grey" headingText="Turbafi" />
            <VStack spacing={8} maxW="800px">
                <VStack px="4" mb="8" w="full" alignItems="start">
                    <Heading as="h2" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold">
                        Our Team
                    </Heading>
                    <Text fontSize={{ base: 'lg', md: 'xl' }} color="gray.600">
                        We&apos;re working to help make great events happen in great places.
                    </Text>
                </VStack>
                {teamDetails.map(({ name, image, bio }) => (
                    <VStack key={name} px="4" spacing="4" w="full">
                        <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold">
                            {name}
                        </Heading>
                        <Avatar name={name} src={image} size="2xl" />
                        <Text
                            textAlign="center"
                            color="gray.600"
                            fontSize={{ base: 'md', md: 'lg' }}
                        >
                            {bio}
                        </Text>
                    </VStack>
                ))}
            </VStack>
        </VStack>
    )
}

export default TeamPage
