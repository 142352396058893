import { Box, Button, Icon, IconButton, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { MdMenu, MdClose } from 'react-icons/md'
import NavMenuOptions from './NavMenuOptions'

function NavDropDown({ menuColour }: { menuColour: 'grey' | 'green' }) {
    const [isOpen, setIsOpen] = useState(false)

    const primary = menuColour === 'grey' ? 'plejGreen.500' : 'plejGrey.300'
    const secondary = menuColour === 'grey' ? 'plejGrey.300' : 'plejGreen.500'

    return (
        <Box position="relative">
            <IconButton
                bg="transparent"
                borderRadius="full"
                _hover={{
                    bg: menuColour === 'grey' ? 'whiteAlpha.200' : 'blackAlpha.50',
                }}
                boxShadow="none"
                icon={<Icon as={isOpen ? MdClose : MdMenu} boxSize={6} color={primary} />}
                aria-label="open close navigation menu"
                onClick={() => setIsOpen(!isOpen)}
            />

            {/* Mobile Menu Overlay */}
            <Box
                position="fixed"
                top="0"
                left="0"
                w="100vw"
                h="100vh"
                bg={secondary}
                zIndex="99"
                opacity="0.95"
                transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
                transition="transform 0.3s ease-in-out"
            />

            {/* Mobile Menu Content */}
            <VStack
                position="fixed"
                top="20"
                left="0"
                right="0"
                bg="transparent"
                w="100%"
                spacing={4}
                p={4}
                zIndex="100"
                transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
                transition="transform 0.3s ease-in-out"
            >
                {NavMenuOptions.map((name) => (
                    <Button
                        key={name}
                        variant="ghost"
                        w="full"
                        size="lg"
                        color={primary}
                        textTransform="capitalize"
                        fontSize="xl"
                        fontWeight="medium"
                        _hover={{
                            bg: menuColour === 'grey' ? 'whiteAlpha.200' : 'blackAlpha.50',
                        }}
                        as="a"
                        href={`/${name}`}
                    >
                        {name}
                    </Button>
                ))}
                <Button
                    w="full"
                    size="lg"
                    bg={primary}
                    color={secondary}
                    fontSize="xl"
                    fontWeight="medium"
                    _hover={{
                        bg: 'plejGreen.600',
                    }}
                    as="a"
                    href="/login"
                >
                    Login
                </Button>
            </VStack>
        </Box>
    )
}

export default NavDropDown
