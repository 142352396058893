import { Box, Button, FormControl, FormLabel, Heading, Input, Text, VStack } from '@chakra-ui/react'
import checkAccess from '@helpers/authentication/checkAccess'
import initialiseOrganiserMetadata from '@services/initialiseOrganiserMetadata.service'
import { useStytch, useStytchUser } from '@stytch/react'
import { SessionTokens } from '@stytch/vanilla-js'
import InfoSection from '@v1_atoms/InfoSection/InfoSection.component'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
// import AccountDetailsUserInfo from '@v1_molecules/AccountDetailsUserInfo/AccountDetailsUserInfo.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import posthog from 'posthog-js'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

function AccountDetailsBody() {
    const maxCenterWidth = '800px'
    const stytch = useStytch()
    const { user } = useStytchUser()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string | undefined>()

    const { register, handleSubmit, formState } = useForm<{ organiserName: string }>()

    const initialiseOrganiser = async (formData: { organiserName: string }) => {
        setIsLoading(true)

        let tokens: SessionTokens | null

        try {
            tokens = stytch.session.getTokens()
        } catch (err) {
            setSubmitError('Failed to get your session details from our authentication provider')
            setIsLoading(false)
            return
        }

        const result = await initialiseOrganiserMetadata(
            formData.organiserName,
            tokens!.session_jwt,
        )

        setIsLoading(false)

        if (!result.success) {
            setSubmitError(result.error)
            return
        }

        window.location.reload()
    }

    if (!user) {
        return <Text>Loading....</Text>
    }

    const { organiserDetails } = user!.trusted_metadata! as {
        organiserDetails: {
            id: string
            name: string
        }
    }

    //  Earliest opportunity to idenitfy organiser user in Posthog o11y
    posthog.identify(organiserDetails.id, {
        email: user.emails[0].email ?? 'unavailable',
        name: organiserDetails.name,
    })

    return (
        <VStack w="full" textColor="plejGreen.500">
            <NavHeading menuColour="green" headingText="Turbafi" />
            <Heading as="h2">
                Hello{' '}
                {(user.trusted_metadata!.organiserDetails as { name: string })?.name || `new user`}
            </Heading>
            <Text>Welcome to your account space</Text>
            {checkAccess(user!.trusted_metadata, ['organiser.create']) ? (
                <Button as="a" href="/organiser/create-event" mt={4} maxW="800">
                    Create Event
                </Button>
            ) : (
                <InfoSection maxCenterWidth={maxCenterWidth}>
                    <form
                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        onSubmit={handleSubmit(initialiseOrganiser)}
                    >
                        <VStack w="full" spacing={8}>
                            <FormControl isInvalid={!!formState.errors.organiserName}>
                                <FormLabel>Organiser name:</FormLabel>
                                <Input
                                    w="full"
                                    variant="createEventGreen"
                                    {...register('organiserName', {
                                        required: true,
                                    })}
                                />
                            </FormControl>
                            <Button isLoading={isLoading} type="submit">
                                Become an organiser
                            </Button>
                            {submitError ? (
                                <Box
                                    maxW="800px"
                                    w="full"
                                    borderColor="red.600"
                                    borderWidth="2pt"
                                    borderStyle="solid"
                                    backgroundColor="red.100"
                                    color="red.600"
                                    borderRadius={6}
                                    padding={2}
                                    mb={6}
                                >
                                    <Text>{submitError}</Text>
                                </Box>
                            ) : null}
                        </VStack>
                    </form>
                </InfoSection>
            )}
        </VStack>
    )
}

export default function AccountDetails() {
    return (
        <ProtectedPage>
            <AccountDetailsBody />
        </ProtectedPage>
    )
}
